import { Link } from "react-router-dom";

const ErrorRoute = ()=> {
    return(
        <div className="display__container f-center">
            <h1 className="display__title">Erreur 404</h1>
            <p>Désolé, une erreur est survenue.</p>
            <Link to={'/'}>
                <button 
                    className="main__btn"
                    onClick={(e) => {
                        window.top.location = "https://webdemo.smart-blueding.fr";
                    }}
                >
                    Retourner à la page d'accueil
                </button>
            </Link>
        </div>
    )
}

export default ErrorRoute;