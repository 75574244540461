import React, { useState, useRef } from "react";
import useFileContext from "../../hooks/useFileContext";

const Topics = ({ machine }) => {
    const [successfullyCopied, setSuccessfullyCopied] = useState(false);
    const [popupIndex, setPopupIndex] = useState("");
    const { fetchTopic } = useFileContext();
    const topicRefs = useRef([]);

    const copyUrl = async (display, index, machineId, machine) => {
        console.log("machine", machine.unique_id);
        const lowerDisplay = display.toLowerCase();
        const textToCopy = `https://webdemo.smart-blueding.fr/${lowerDisplay}/${machineId}/${index}`
        if (navigator.clipboard && navigator.clipboard.writeText) {
            try {
                
                await navigator.clipboard.writeText(textToCopy);
            } catch (error) {
                console.log("navigator clipboard operational but an error occured", machineId);
            }
            setPopupIndex(`${index}${machineId}`);
            setSuccessfullyCopied(true);
        } else {
            console.error('Clipboard API or writeText method not supported');
        };
    };

    const handleTopic = async (topic, topicId, machineId, e) => {
        clearFocusStates();
        clearTopicItems(e.target.closest(".list"));            

        if (e.target.classList.contains("listItem__topic")) {
            e.target.classList.add("listItem__focus");
            e.target.querySelector('.topic__ccImg').classList.add("topic__ccImg__focus");
        } else {
            const parentElm = e.target.closest(".listItem__topic");
            parentElm.classList.add("listItem__focus");
            parentElm.querySelector('.topic__ccImg').classList.add("topic__ccImg__focus");
        }
        try {
            await fetchTopic(topic, topicId, machineId);
        } catch (error) {
            console.log(error, "issue while fetching topic");
        }
    };

    const clearTopicItems = (list) => {
        list.querySelectorAll(".listItem__topic").forEach((topic) => {
            topic.classList.remove("listItem__focus");
            topic.querySelector('.topic__ccImg').classList.remove("topic__ccImg__focus");
        })
    }

    const clearFocusStates = () => {
        topicRefs.current.forEach((ref) => {
            ref.classList.remove("listItem__focus");
            ref.querySelector('.topic__ccImg').classList.remove("topic__ccImg__focus");
        });
    };

    const renderTopics = () => {
        return machine.topics.map((top, index) => {
            const isLastItem = index === machine.topics.length - 1;
            const listItemClassName = isLastItem
                ? "listItem__topic"
                : "listItem__topic border";

            return (
                <div
                    className={listItemClassName}
                    key={index}
                    ref={(el) => (topicRefs.current[index] = el)}
                    onClick={(e) => {
                        handleTopic(
                            top,
                            index,
                            machine.unique_id,
                            e
                        );
                    }}
                    >
                    <p
                    onClick={(e) => {
                        handleTopic(
                            top,
                            index,
                            machine.unique_id,
                            e
                        );
                    }}
                    >
                        <span
                    onClick={(e) => {
                        handleTopic(
                            top,
                            index,
                            machine.unique_id,
                            e
                        );
                    }}>{`${top.topic_title} -`}</span>
                        <span className="topic__url">{`https://webdemo.smart-blueding.fr/${top.topic_display.toLowerCase()}/${machine.unique_id}/${index}`}</span>
                    </p>
                    <span
                        className="topic__ccImg"
                        onClick={(e) => {
                            copyUrl(
                                top.topic_display,
                                index,
                                machine.unique_id,
                                machine
                            );
                        }}
                    >
                        {successfullyCopied &&
                            popupIndex === `${index}${machine.unique_id}` && (
                                <p className="popup">Link Copied !</p>
                            )}
                    </span>
                </div>
            );
        });
    };

    return renderTopics();
};

export default Topics;
