import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
import { Oval } from "react-loader-spinner";
import ErrorRoute from "../components/route/ErrorRoute";
import "./templates.css";

const Logs = () => {
    const { machine_id, topic_id } = useParams();
    const location = useLocation();
    const current_display = location.pathname.split("/")[1];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState(null);
    const [lastMessage, setLastMessage] = useState(null);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await JsonHandler(`message/${machine_id}/${topic_id}`, "POST");
                if (res.success) {

                    if(!lastMessage || res.message != lastMessage){
                        if(message){
                            setMessage(res.message+'\n \n'+message);
                        }
                        else{
                            setMessage(res.message);
                        }
                        setLastMessage(res.message);
                    }
                    setCurrentTopic(res.topic);
                    setBgData(res.background);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if(!message) fetchData();

        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        const intervalId = setInterval(fetchData, 3000);
        const intervalDatas = setInterval(dataLoaded, 15000);
        return () => clearInterval(intervalId, intervalDatas);
    }, [machine_id, topic_id, setLoading, setDataEmpty, message, setBgData]);

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                    className="display__container tile__background"
                    style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message && <p className="text__scrollable">{message}</p>}
                </section>
            )}
        </>
    );
};

export default Logs;
