import {useEffect, useState} from "react";
import {useParams, useLocation } from "react-router-dom";
import JsonHandler from "../service/JsonHandler";
import ErrorRoute from "../components/route/ErrorRoute";
import { Oval } from "react-loader-spinner";
import "./templates.css";

const Media = () => {
    let {machine_id, topic_id} = useParams();
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState([]);
    const location = useLocation();
    const current_display = location.pathname.split("/")[1];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const index = topic_id;

    useEffect(() => {
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/${machine_id}/${index}`, "POST");
            if(res.success){
                setMessage((JSON.parse(res.message)));
                setBgData(res.background);
                setCurrentTopic(res.topic);
                setLoading(false);
            }
        }
        if (!message) {
            fetchTopic();
        }
        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        const intervalId = setInterval(fetchTopic, 3000);
        const intervalDatas = setInterval(dataLoaded, 15000);
        return () => clearInterval(intervalId, intervalDatas);
    }, [ machine_id, setCurrentTopic, message, index, setLoading, setBgData]);    

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                {currentTopic && (
                <>
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message && message.type === "video" ? (
                        //? because of rotation metadata in Firefox and borwsers that doesn't support native mobile video,
                        //? The video is upside down (╯▔皿▔)╯
                        <video 
                        playsInline={true} 
                        preload="metadata" 
                        src={message.url}
                        controls
                        controlsList="nodownload"
                        className="media__video"
                        ></video>
                        ) : (
                        <img src={message.url} alt="" className="media__img" />
                    )}
                </>
                )}
        </section>
        )}
        </>
        );
};

export default Media;