import React, {useEffect, useState } from "react";
import {useParams, useLocation} from "react-router-dom";
import ErrorRoute from "../components/route/ErrorRoute";
import JsonHandler from "../service/JsonHandler";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Oval } from "react-loader-spinner";
import "./templates.css";

const PieCharts = () => {
    let {machine_id, topic_id} = useParams();
    const [currentTopic, setCurrentTopic] = useState({});
    const location = useLocation();
    const current_display = location.pathname.split("/")[1];
    const [message, setMessage] = useState(null);
    const [pieData, setPieData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const index = topic_id;
    
    const colors = [
        "#d85052",
        "#669BBC",
        "#d62a6f",
        "#7d9910",
        "#d67220",
        "#61ccd6",
        "#88d661",
        "#6c23a3",
        "#db4c83",
        "#AE8CEC",
        "#F3A712",
        "#D7CF07",
        "#a97ad6",
        "#28AFB0"
    ];  
    
    useEffect(() => {
        let currentTimer = 15000;
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/piechart/${machine_id}/${index}`, "POST");
            if(res.success){
                if(message !== res.message){
                    setMessage(res.message);
                    setCurrentTopic(res.topic);
                    setBgData(res.background);

                    //set timer update for 
                    if (res.topic && res.topic.topic_params.timePeriod && res.topic.topic_params.timePeriod > 0 ) {
                        currentTimer = `${res.topic.topic_params.timePeriod}0000`;
                    }
                    setLoading(false);
                }
            }
        }
        if (!message) {
            fetchTopic();
        }
        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        const intervalId = setInterval(fetchTopic, currentTimer);
        const intervalDatas = setInterval(dataLoaded, 20000);
        return () => clearInterval(intervalId, intervalDatas)
    }, [ setMessage, setCurrentTopic, machine_id, index, message, setLoading, setDataEmpty, setBgData]);    

        
    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

    useEffect(() => {
        if (message) {
            const renderPieData = () => {
                const tempDatas = [];
                let val = null;
                for (const key in message) {
                    parseFloat(message[key]) > 0 ? val = parseFloat(message[key]) : val = 0;
                    tempDatas.push({ name: key, value: val});
                }
                setPieData(tempDatas);
            };

            renderPieData();
        }
    }, [message, setPieData])
    
    const renderLabel = ({ cx, cy, midAngle, outerRadius, index }) => {
        const radius = outerRadius + 30;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        const keys = Object.keys(message);
        if (keys.length > index) {
            const key = keys[index];
            const value = message[key] > 0 ? message[key] : 0;
            return (
                <text 
                x={x}
                y={y}
                fill="#fff"
                fontFamily="Inter"
                fontSize="0.7rem"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                    {/* {`${key} : ${value}`} */}
                    {value}
            </text>
            );
        }
    return null;
    };

    const renderLegends = (pieData) => {
        return pieData.map((entry, index) => (
            <div key={`legend-${index}`} className="legend__item">
                <span className="legend__color" style={{ backgroundColor: colors[index % colors.length] }} />
                <span className="legend__label">{entry.name}</span>
            </div>
        ))
    }
    
    if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }

    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
        {!rightDisplay ? (
            <ErrorRoute />
        ) : (
            <section 
            className="display__container tile__background"
            style={displayTileStyle(bgData, currentTopic.topic_opacity)}
            >
                {currentTopic && (
                <>
                    <h2 className="display__title">{currentTopic.topic_title}</h2>
                    {message && colors && pieData && (
                        <div className="pieChart">
                        <div className="charts__container">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart >
                                    <Pie
                                        data={pieData}
                                        cx="50%"
                                        cy="50%"
                                        label={renderLabel}
                                        outerRadius="50%"
                                        fill="#448fe5"
                                        dataKey="value"
                                    >
                                        {pieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                                </ResponsiveContainer>
                        </div>
                        <div className="legend">
                            {renderLegends(pieData)}
                        </div>
                        </div>
                    )}
                </>
                )}
        </section>
            )}
        </>
    );
};

export default PieCharts;