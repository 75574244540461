import {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import { Oval } from "react-loader-spinner";
import ErrorRoute from "../components/route/ErrorRoute";
import JsonHandler from "../service/JsonHandler";

const Links = () => {
    let {machine_id, topic_id} = useParams();
    const [currentTopic, setCurrentTopic] = useState({});
    const [message, setMessage] = useState(null);
    const location = useLocation();
    const current_display = location.pathname.split("/")[1];
    const [loading, setLoading] = useState(true);
    const [rightDisplay, setRightDisplay] = useState(true);
    const [dataEmpty, setDataEmpty] = useState(false);
    const [bgData, setBgData] = useState(null);
    const index =topic_id;

    useEffect(() => {
        const fetchTopic = async () => {
            const res = await JsonHandler(`message/${machine_id}/${index}`, "POST");
            if(res.success){
                setMessage(res.message);
                setCurrentTopic(res.topic);
                setBgData(res.background);
                setLoading(false);
            }
        };
        if (!message) {
            fetchTopic();
        }
        const dataLoaded = async () => {
            message ? setDataEmpty(false) : setDataEmpty(true);
        }
        const intervalId = setInterval(fetchTopic, 3000);
        const intervalDatas = setInterval(dataLoaded, 15000);
        return () => clearInterval(intervalId, intervalDatas);
    }, [ setMessage, machine_id, index, message, setLoading, setDataEmpty, setBgData]);    

    useEffect(() => {
        const topicDisplay = currentTopic.topic_display ? currentTopic.topic_display.toLowerCase() : '';
        setRightDisplay(topicDisplay === current_display);
    }, [currentTopic, current_display]);

if (loading) {
        return(
            <section className="display__container tile__background">
                { dataEmpty ? (
                    <span className="legend__label">No data found yet.</span>
                ) : (
                    <Oval
                    height="80"
                    width="80"
                    strokeWidth="4"
                    strokeWidthSecondary="4"
                    color="white"
                    secondaryColor=""
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    />
                )}
            </section>
        );
    }
    
    const displayTileStyle = (bgData, top_op) => {
        if(bgData.url){
            return {   
                backgroundImage: `url('${bgData.url}')`,
                opacity: top_op || bgData.opacity || 1,
                backgroundPosition: `center center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`
            }
        }else{
            return {   
                opacity: top_op || bgData.opacity || 1,
            }

        }
    }

    return (
        <>
            {!rightDisplay ? (
                <ErrorRoute />
            ) : (
                <section 
                className="display__container tile__background"
                style={displayTileStyle(bgData, currentTopic.topic_opacity)}
                >
                {currentTopic && (
                    <>
                        <h2 className="display__title">{currentTopic.topic_title}</h2>
                        {message && (
                            <a href={message} rel="noreferrer" target="_blank" >{message}</a>
                        )}
                    </>
                    )}
            </section>
            )}
        </>
    );
};

export default Links;